import { generateSpecialtyMapData } from './helpers/generateSpecialtyMapData';
const { scrollToElement } = require('./helpers/scrollToElement');

window.HMSBuyInStore = class BuyInStore {
  constructor() {
    this.initPopup();
  }

  reset() {
    const $radius = $('#radius');
    $radius.val($radius.find('option')[0].value).trigger('change');
    $('#form-buy-in-store__ZipCode').val('');
    $('.js-buy-in-store-crate').removeClass('u-display-none');
    $('.js-specialty-stores-tab').removeClass('active');
    $('.js-specialty-stores-tab-content').removeClass('active');

    ['buy-in-store', 'buy-in-store-no-results', 'change-product-location-buy'].forEach(className =>
      $(`.js-${className}`).addClass('u-display-none'));
  }

  initPopup() {
    $('.js-buyinstore-popup-trigger').magnificPopup({
      mainClass: 'buy-in-store-popUp',
      closeBtnInside: true,
      type: 'inline',
      callbacks: {
        close: () => this.reset()
      }
    });
  }

  activateTabs(data) {
    if (data.results.IriStores.length === 0) {
      $('.js-specialty-stores-tab').addClass('active');
      $('.js-specialty-stores-tab-content').addClass('active');
      $('.js-iri-stores-tab-content').removeClass('active');
      $('.js-iri-stores-tab').addClass('u-display-none');
    } else {
      $('.js-iri-stores-tab')
        .removeClass('u-display-none')
        .addClass('active');
      $('.js-iri-stores-tab-content').addClass('active');
    }

    $('.js-specialty-stores-tab')
      .toggleClass('u-display-none', data.results.SpecialtyStores.length === 0);
  }

  renderStores(data, selector) {
    this.activateTabs(data);

    const storesData = selector === '.js-iri-stores-tab'
      ? data.results.IriStores : data.results.SpecialtyStores;
    const { MilesText, MapLinkText } = data.results;
    const $table = $(`${selector}-content .js-table`);
    const [storeTh, adressTh, mapsTh] = $table.find('thead th');
    const storeLabel = storeTh.innerText;
    const adressLabel = adressTh.innerText;
    const mapsLabel = mapsTh.innerText;

    if (storesData.length > 0) {
      this.hideForm();
      $('.js-buy-in-store').removeClass('u-display-none');
      $(selector).removeClass('u-display-none');
      $('.js-specialty-stores-tab-content tbody').empty();

      storesData.forEach(element => {
        const { Name, Address, City, PostalCode, ZipCode, Distance } = element;

        $table.find('tbody').append(
          `<tr>
            <td data-label="${storeLabel}">
              ${Name}
              <br>
              ${Distance ? `(${Distance} ${MilesText})` : ''}
            </td>
            <td data-label="${adressLabel}">
              ${Address}
            </td>
            <td data-label="${mapsLabel}">
              <a
                href="https://maps.google.com/maps?q=${Address}${City}${PostalCode ? PostalCode : ZipCode}"
                target="_blank"
                class="buy-in-store-link"
              >
                ${MapLinkText}
              </a>
            </td>
          </tr>`
        );
      });
    }
  }

  hideForm() {
    $('.js-buy-in-store-crate').addClass('u-display-none');
    $('.js-change-product-location-buy').removeClass('u-display-none');
  }

  changeProductLocation() {
    $('.js-change-product-location-buy').on('click', event => {
      this.reset();
      $(event.currentTarget).addClass('u-display-none');
      scrollToElement($('.js-iri-form-container'));
    });
  }

  renderMap(storesData) {
    if (!storesData.length) {
      return;
    }

    const map = new window.google.maps.Map(document.querySelector('.buy-in-store-map'), {
      center: new window.google.maps.LatLng(0, 0),
      zoom: 0,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP
    });

    const latlng = [];
    const latlngbounds = new window.google.maps.LatLngBounds();

    storesData.forEach(element => {
      latlng.push(new window.google.maps.LatLng(parseFloat(element.Latitude), parseFloat(element.Longitude)));
      latlng.forEach(childElement => {
        latlngbounds.extend(childElement);
      });
    });

    map.fitBounds(latlngbounds);

    $('.js-buy-in-store').removeClass('u-display-none');

    this.renderEachMarker(storesData, map);
  }

  initMap(data) {
    if (data.results.SpecialtyStores.length > 0) {
      const specialtyData = generateSpecialtyMapData(data);
      Promise.all(specialtyData.promises).then(() =>
        this.renderMap([...specialtyData.mapData, ...data.results.IriStores]));
    } else {
      this.renderMap(data.results.IriStores);
    }
  }

  renderEachMarker(data, map) {
    if (data.length > 0) {
      const markersArray = [];

      data.forEach(element => {
        const { Name, Address, Latitude, Longitude, Phone } = element;
        markersArray.push([{ lat: parseFloat(Latitude), lng: parseFloat(Longitude) }, Name, Address, Phone]);
      });

      markersArray.forEach(([position, title, address, phone]) => {
        const marker = new window.google.maps.Marker({
          position,
          map,
          optimized: false,
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: `<div class="buy-in-store-marker">
                      <h1 class="marker-heading">${title}</h1>
                      <div id="bodyContent" class="marker-text-content markerText">
                        <p>${address}</p>
                        <p>${phone}</p>
                      </div>
                    </div>`,
        });

        marker.addListener('mouseover', () => {
          infoWindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });

        marker.addListener('mouseout', () => {
          infoWindow.close();
        });
      });
    }
  }

  render(data) {
    this.changeProductLocation();

    if (data.results.IriStores.length > 0 || data.results.SpecialtyStores.length > 0) {
      this.initMap(data);

      // Do not call the method for Where to buy
      if ($('.js-iri-stores-tab-content').length > 0) {
        $('.js-iri-stores-tab-content tbody').empty();
        this.renderStores(data, '.js-iri-stores-tab');
      }

      if ($('.js-specialty-stores-tab-content').length > 0) {
        this.renderStores(data, '.js-specialty-stores-tab');
      }
    } else {
      this.hideForm();
      $('.js-buy-in-store-no-results').removeClass('u-display-none');
    }
  }
};
