export const generateSpecialtyMapData = data => {
  const mapData = [];
  const promises = [];
  const geocoder = new window.google.maps.Geocoder();

  const generateData = store => {
    const { Address, City, PostalCode, CountryCode } = store;

    return new Promise(resolve => {
      geocoder.geocode( { 'address': `${Address}, ${City}, ${PostalCode}, ${CountryCode}`}, function(results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          mapData.push({
            ...store,
            Longitude: results[0].geometry.location.lng(),
            Latitude: results[0].geometry.location.lat()
          });
        }
        resolve();
      });
    });
  };

  // Splice added to prevent reaching quota set by Google api
  [...data.results.SpecialtyStores].splice(0, 11).forEach((store) => {
    promises.push(generateData(store));
  });

  return ({ promises, mapData });
};
